/* COMPONENTS */
import CallToAction from "../components/call-to-action";
import InstagramWall from "../components/instagram";
import AboutAndBlogs from "../components/about-and-blogs";
import ActiveListings from "../components/active-listings";
import Banner from "../components/banner";

const Home = () => {
  return (
    <>
      <section className="homepage-3">
        {/* BANNER */}
        <Banner />

        {/* ACTIVE LISTINGS */}
        <ActiveListings />

        {/* ABOUT AND BLOGS */}
        <AboutAndBlogs />

        {/* TESTIMONIALS */}
        {/* <Testimonials /> */}

        {/* INSTAGRAM WALL */}
        <InstagramWall />

        {/* CALL TO ACTION */}
        <CallToAction />
      </section>
    </>
  );
};

export default Home;
